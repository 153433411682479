import React, { useState, useEffect } from "react"
import { useAuthUser } from "react-auth-kit"
import Day from "./Day"

function Calender({ refresh }) {
	const auth = useAuthUser()
	const user = auth()
	const [roster, setRoster] = useState([])
	const [goals, setGoals] = useState([])

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()

	const getDaysInMonth = (month, year) => {
		return new Date(year, month, 0).getDate()
	}

	const attendances_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-01&endDate=${currentYear}-${currentMonth}-${getDaysInMonth(
		currentMonth,
		currentYear
	)}&size=31&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`
	const goals_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/goals?startDate=${currentYear}-${currentMonth}-01&endDate=${currentYear}-${currentMonth}-${getDaysInMonth(
		currentMonth,
		currentYear
	)}&size=31`

	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setRoster(result.content)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey, refresh])

	useEffect(() => {
		fetch(goals_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setGoals(result.content)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [goals_url, user.apikey])

	const [attandanceCalender, setAttandanceCalender] = useState([])
	useEffect(() => {
		const daysInWeek = [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		]
		const calendar = []
		const month = new Date().getMonth()
		const year = new Date().getFullYear()
		const date = new Date(year, month, 1)

		while (date.getMonth() === month) {
			const dayOfWeek = date.getDay()
			if (dayOfWeek >= 0 && dayOfWeek <= 5) {
				// Monday (1) to Saturday (6)
				calendar.push({
					date: new Date(date),
					day: daysInWeek[dayOfWeek - 1],
				})
			}
			date.setDate(date.getDate() + 1)
		}

		setAttandanceCalender(calendar)

		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="border-t-[1px] border-[#fff5eb] h-full grow-[1]">
			<ul className=" gap-[2px] w-full pt-[10px] grid grid-cols-6 ">
				{attandanceCalender &&
					attandanceCalender.map((day) => {
						if (day) {
							return (
								<Day
									day={day}
									roster={roster}
									goals={goals}
									key={day.date}
								/>
							)
						} else {
							return null
						}
					})}
			</ul>
		</div>
	)
}

export default Calender
