import React, { useState, useEffect } from "react"
import { createPortal } from "react-dom"

function DayReport({ isOpen, onClick, onClose, date }) {
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		setIsMounted(true)
		return () => setIsMounted(false)
	}, [])

	if (!isMounted || !isOpen) return null
	return createPortal(
		<div className="fixed z-[11] bg-white rounded-[2px] sm:w-[calc(100%-32px)] md:max-w-[600px] flex flex-col gap-[30px] sm:px-[16px] sm:py-[30px] md:p-[50px_30px] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%]">
			<div className="flex-col flex gap-[6px]">
				<div className="m-auto">
					<svg
						width="60"
						height="60"
						viewBox="0 0 60 60"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M30 5C16.25 5 5 16.25 5 30C5 43.75 16.25 55 30 55C43.75 55 55 43.75 55 30C55 16.25 43.75 5 30 5ZM30 50C18.975 50 10 41.025 10 30C10 18.975 18.975 10 30 10C41.025 10 50 18.975 50 30C50 41.025 41.025 50 30 50ZM41.475 18.95L25 35.425L18.525 28.975L15 32.5L25 42.5L45 22.5L41.475 18.95Z"
							fill="#0EBF51"
						/>
					</svg>
				</div>
				<div className="text-[20px] font-medium text-center">
					Successful
				</div>
				<div className="my-[1rem] md:w-[30rem]">
					<p className="font-[500] text-[14px] ml-[4px] text-[#333333] leading-[1.5rem]  text-center">
						{`Your daily report for ${new Date(
							date
						).toDateString()} was submitted
						successfully`}
					</p>
				</div>

				<button
					onClick={onClose}
					className="text-[16px] md:w-[184px] md:mx-auto bg-[#f2994a] text-white leading-[44px] h-[44px] rounded-[5px] font-[600] flex items-center justify-center"
				>
					Close
				</button>
			</div>
		</div>,
		document.getElementById("modal-root")
	)
}

export default DayReport
