import React, { useEffect, useState } from "react"
import { useAuthUser } from "react-auth-kit"

const NotificationsPage = () => {
	const auth = useAuthUser()
	const user = auth()

	const [register, setRegister] = useState("Fetching Data")
	const [attendanceColor, setAttendanceColor] = useState("#d1d1d1")

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()
	const currentDay = new Date().getDate()
	const attendances_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(2, "0")}&endDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(
		2,
		"0"
	)}&page=0&size=20&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`

	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.content[0].attendanceType === "P") {
					setRegister("Present")
					setAttendanceColor("#3fa456")
				} else if (result.content[0].attendanceType) {
					setRegister("Away")
					setAttendanceColor("#ff7373")
				} else {
					setRegister("Please mark attendance")
					setAttendanceColor("#d1d1d1")
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey])

	useEffect(() => {
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<section className="max-w-[535px] w-full mx-auto px-[19px] sm:grid sm:pb-[80px]">
			<div className="flex justify-between py-[19px]">
				<div className="">
					<div className="text-[20px] font-bold">Notifications</div>
					<div
						className="text-[13px] mt-[3px]"
						style={{ color: attendanceColor }}
					>
						{register}
					</div>
				</div>
			</div>
			<div className="py-[19px] grid gap-[14px] rounded-[2px] mt-[19px] sm:order-4">
				{/* <ul className="flex flex-col gap-[10px]">
					<li className="flex flex-col gap-[0.3rem] border-b-[#E2E2E2] border-b-[1px] pb-[13px]">
						<div className="flex justify-between">
							<div className="text-[18px] font-[500]">
								Daily target reminder
							</div>
							<div className="text-[#F19B3E] uppercase text-[13px] leading-[21px]">
								22 JUNE 12:00 PM
							</div>
						</div>
						<div className="text-[14px] text-[#6C6C6C] font-[400]">
							CHEKE001, you are 7 onboarding away from your
							target. Keep pushing!
						</div>
					</li>
					<li className="flex flex-col gap-[0.3rem] border-b-[#E2E2E2] border-b-[1px] pb-[13px]">
						<div className="flex justify-between">
							<div className="text-[18px] font-[500]">
								Daily target reminder
							</div>
							<div className="text-[#F19B3E] uppercase text-[13px] leading-[21px]">
								22 JUNE 12:00 PM
							</div>
						</div>
						<div className="text-[14px] text-[#6C6C6C] font-[400]">
							CHEKE001, you are 7 onboarding away from your
							target. Keep pushing!
						</div>
					</li>
				</ul> */}
			</div>
		</section>
	)
}
export default NotificationsPage
