import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { createPortal } from "react-dom"
import { useAuthUser } from "react-auth-kit"

function RegisterUser({ isOpen, onClick, onClose, cell }) {
	const navigate = useNavigate()
	const auth = useAuthUser()
	const user = auth()

	const [url] = useState(
		`${process.env.REACT_APP_BASE_URL}/api/v2/che-tool/init-collect`
	)

	const [isMounted, setIsMounted] = useState(false)
	const [error, setError] = useState(null)

	const handleSubmit = () => {
		fetch(url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
			body: JSON.stringify({ clientPhone: `+${cell}` }),
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.id) {
					onClose()
					navigate(`/register/${result.id}/+${cell}`)
				} else {
					setError(result.message)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	useEffect(() => {
		setIsMounted(true)
		return () => setIsMounted(false)
	}, [])

	if (!isMounted || !isOpen) return null
	return createPortal(
		<div className="fixed z-[11] bg-white rounded-[2px] sm:w-[calc(100%-32px)] md:max-w-[553px] flex flex-col gap-[30px] sm:px-[16px] sm:py-[30px] md:p-[60px] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%]">
			<div className=" bg-cover bg-[#ffedda] w-[100px] h-[100px] flex m-auto rounded-[10rem]">
				<svg
					className="h-[50px] pb-[3px] m-auto"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z"
						fill="#15486B"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M16.2211 18.246C16.2489 18.2928 16.2601 18.3477 16.2526 18.4017C16.2452 18.4557 16.2196 18.5055 16.1801 18.543C14.511 20.1236 12.2988 21.0031 10.0001 21C7.70132 21.0031 5.48913 20.1236 3.82005 18.543C3.78068 18.5054 3.75527 18.4555 3.748 18.4015C3.74073 18.3475 3.75203 18.2927 3.78005 18.246C4.94205 16.318 7.29105 15 10.0001 15C12.7091 15 15.0571 16.318 16.2211 18.246Z"
						fill="#15486B"
					/>
					<path
						d="M18.5 10.5V16.5M15.5 13.5H21.5"
						stroke="#F19B3E"
						strokeLinecap="round"
					/>
				</svg>
			</div>
			<div className="flex-col flex gap-[6px]">
				<button
					onClick={onClose}
					className="absolute top-[1.5rem] right-[1.5rem] hover:underline text-[#3FA456]"
				>
					Close
				</button>
				<div className="text-[20px] font-medium text-center">
					Register New User
				</div>
				<div className="text-[14px] text-center leading-[1.3] font-normal">
					You are about to register {cell} as an offline user, Click
					register user to complete the registration form.
				</div>
			</div>
			{error && (
				<div className="text-[12px] text-[#FF7373] text-center leading-[1]">
					{error}
				</div>
			)}
			<div className="grid justify-around">
				<button
					onClick={handleSubmit}
					className="text-white bg-[#3FA456] text-[18px] font-normal leading-[48px] px-[25px] rounded-[8px]"
				>
					Register User
				</button>
			</div>
		</div>,
		document.getElementById("modal-root")
	)
}

export default RegisterUser
