import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
const NavBar = () => {
	const navigate = useNavigate()

	const goBack = () => {
		navigate(-1) // -1 means going back to the previous page
	}

	return (
		<div className="bg-[#FFFFFF] md:h-[100dvh] z-20 md:sticky md:top-0 grid md:grid-rows-3 sm:fixed bottom-0 sm:w-full">
			<nav className="w-full hidden md:block">
				<ul className="grid grid-rows-[35px_35px] gap-[20px] border-b-[1px] border-b-[#E3E3E3] w-[35px] mx-auto py-[19px]">
					<li className="h-[35px] w-[35px] m-auto">
						<NavLink
							to="/"
							className="grid h-full w-full bg-[#F3F3F3] rounded-[35px] cursor-pointer"
						>
							<svg
								className="h-[20px] m-auto"
								viewBox="0 0 16 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.99171 7.01644C10.0807 6.13015 11.1484 5.48322 11.1795 5.19604C11.2154 4.86898 10.941 4.50482 10.5589 4.514C10.2259 4.52198 9.19483 5.76082 8.76046 5.63637C8.32655 5.51273 8.51242 3.99709 8.30578 3.7498C8.19132 3.61179 8.04971 3.54638 7.79045 3.54199C7.5308 3.54678 7.38919 3.61179 7.27473 3.7494C7.06816 3.99749 7.25402 5.51273 6.82005 5.63637C6.3853 5.76082 5.35466 4.52198 5.02122 4.514C4.63951 4.50482 4.3655 4.86898 4.4014 5.19604C4.43291 5.48322 5.49984 6.13015 5.58879 7.01644C5.67773 7.9031 3.80471 11.8126 3.80471 18.3167C3.80471 18.9537 6.28479 19.4986 7.79045 19.4986C9.29576 19.4986 11.7766 18.9537 11.7766 18.3167C11.7766 11.8126 9.90279 7.90263 9.99171 7.01644Z"
									fill="#15486B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.79047 19.9999C6.76711 20.0028 5.75332 19.8026 4.80779 19.4112C3.86225 19.0197 3.00373 18.4446 2.28187 17.7192C1.55631 16.9981 0.98102 16.1402 0.589332 15.1952C0.197646 14.2502 -0.00265548 13.2368 2.87473e-05 12.2139C-0.00276051 11.1909 0.197452 10.1774 0.589071 9.2324C0.980688 8.28728 1.55593 7.42928 2.28147 6.70812C2.30767 6.6819 2.33875 6.66114 2.37297 6.64693C2.40719 6.63278 2.44386 6.62549 2.4809 6.62549C2.51794 6.62549 2.55461 6.63278 2.58883 6.64693C2.62305 6.66114 2.65414 6.6819 2.68033 6.70812C2.70652 6.73426 2.72729 6.76538 2.74147 6.79962C2.75564 6.8338 2.76293 6.87049 2.76293 6.90752C2.76293 6.94455 2.75564 6.98124 2.74147 7.01548C2.72729 7.04965 2.70652 7.08078 2.68033 7.10692C-0.136779 9.92284 -0.136779 14.5045 2.68033 17.3208C3.34987 17.9938 4.14622 18.5272 5.02331 18.8904C5.90038 19.2535 6.84077 19.4391 7.79007 19.4364C9.72052 19.4364 11.5345 18.6853 12.8993 17.3208C15.7169 14.5045 15.7169 9.92284 12.8993 7.10692C12.8732 7.08078 12.8524 7.04965 12.8382 7.01548C12.824 6.98124 12.8168 6.94455 12.8168 6.90752C12.8168 6.87049 12.824 6.8338 12.8382 6.79962C12.8524 6.76538 12.8732 6.73426 12.8993 6.70812C12.9256 6.6819 12.9567 6.66114 12.9909 6.64693C13.0251 6.63278 13.0618 6.62549 13.0988 6.62549C13.1358 6.62549 13.1725 6.63278 13.2067 6.64693C13.2409 6.66114 13.2721 6.6819 13.2982 6.70812C14.0239 7.42922 14.5992 8.28721 14.9908 9.23233C15.3824 10.1774 15.5826 11.1909 15.5797 12.2139C15.5826 13.2368 15.3825 14.2502 14.9908 15.1953C14.5992 16.1403 14.0239 16.9982 13.2982 17.7192C12.5764 18.4446 11.7179 19.0196 10.7725 19.4111C9.82702 19.8026 8.81375 20.0027 7.79047 19.9999Z"
									fill="#15486B"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8.15702 3.05938C8.15702 3.05938 6.59548 3.25961 5.75709 2.42081C4.9195 1.58202 5.12251 0.0209075 5.12251 0.0209075C5.12251 0.0209075 6.68443 -0.179317 7.52245 0.659473C8.36 1.49906 8.15702 3.05938 8.15702 3.05938Z"
									fill="#F19B3E"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M10.4587 0.0257365C10.4587 0.0257365 10.659 1.58685 9.8198 2.42445C8.98065 3.26204 7.41874 3.05863 7.41874 3.05863C7.41874 3.05863 7.21848 1.49791 8.05769 0.660314C8.89764 -0.17728 10.4587 0.0257365 10.4587 0.0257365Z"
									fill="#1F598A"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.40659 4.18291C4.40659 4.18291 3.7772 5.08472 2.94918 5.12501C2.12116 5.16449 1.40841 4.3269 1.40841 4.3269C1.40841 4.3269 2.03779 3.42469 2.86581 3.38441C3.69383 3.34492 4.40659 4.18291 4.40659 4.18291Z"
									fill="#F19B3E"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.91561 1.56763C3.91561 1.56763 4.81822 2.19702 4.8585 3.02424C4.89799 3.85186 4.0596 4.56461 4.0596 4.56461C4.0596 4.56461 3.15739 3.93562 3.11711 3.108C3.07722 2.28038 3.91561 1.56763 3.91561 1.56763Z"
									fill="#1F598A"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M11.5747 4.60093C11.5747 4.60093 10.6717 3.97193 10.6319 3.14431C10.592 2.31669 11.4303 1.60474 11.4303 1.60474C11.4303 1.60474 12.333 2.23333 12.3729 3.06095C12.4127 3.88857 11.5747 4.60093 11.5747 4.60093Z"
									fill="#F19B3E"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M14.1909 4.11028C14.1909 4.11028 13.5611 5.01209 12.7335 5.05237C11.9055 5.09226 11.1927 4.25427 11.1927 4.25427C11.1927 4.25427 11.8217 3.35206 12.6497 3.31217C13.4781 3.27228 14.1909 4.11028 14.1909 4.11028Z"
									fill="#1F598A"
								/>
							</svg>
						</NavLink>
					</li>
					<li className="h-[35px] w-[35px] m-auto">
						<button
							onClick={goBack}
							to="/"
							className="grid h-full w-full bg-[#F3F3F3] rounded-[35px]  cursor-pointer"
						>
							<svg
								className="w-[17px] m-auto"
								viewBox="0 0 17 13"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16 5.80556V7.19444H3.66667L7.48611 11.0139L6.5 12L1 6.5L6.5 1L7.48611 1.98611L3.66667 5.80556H16Z"
									fill="#F19B3E"
									stroke="#F19B3E"
									strokeWidth="0.5"
								/>
							</svg>
						</button>
					</li>
					{/* <hr className=" border-t-[#E3E3E3] w-[35px] m-auto" /> */}
				</ul>
			</nav>
			<nav className="flex w-full">
				<ul className="grid sm:grid-cols-4 md:grid-rows-[51px_51px_51px_51px] gap-0 w-full nav-links">
					<li className="h-[55px] md:h-[51px]">
						<NavLink
							to="/"
							className={({ isActive }) =>
								isActive
									? "border-[#F19B3E] sm:border-b-2 md:border-l-2 sm:pt-[2px] md:pr-[2px] bg-custom-gradient-sm md:bg-custom-gradient grid h-full w-full cursor-pointer"
									: "inactive-link grid h-full w-full cursor-pointer"
							}
						>
							<svg
								className="h-[20px] md:h-[20px] m-auto"
								viewBox="0 0 19 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.709 3.10526V1.42105C12.709 1.30938 12.7534 1.20229 12.8324 1.12332C12.9113 1.04436 13.0184 1 13.1301 1H15.2354C15.347 1 15.4541 1.04436 15.5331 1.12332C15.6121 1.20229 15.6564 1.30938 15.6564 1.42105V5.63158"
									stroke="#F19B3E"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M12.2876 17.0001V12.7896C12.2876 11.5988 12.2876 11.0034 11.917 10.6338C11.5482 10.2632 10.9528 10.2632 9.76124 10.2632C8.5705 10.2632 7.97513 10.2632 7.60545 10.6338C7.23492 11.0026 7.23492 11.598 7.23492 12.7896V17.0001M11.4455 6.47376C11.4455 6.92044 11.268 7.34883 10.9522 7.66468C10.6363 7.98053 10.2079 8.15797 9.76124 8.15797C9.31456 8.15797 8.88617 7.98053 8.57032 7.66468C8.25447 7.34883 8.07703 6.92044 8.07703 6.47376C8.07703 6.02708 8.25447 5.5987 8.57032 5.28284C8.88617 4.96699 9.31456 4.78955 9.76124 4.78955C10.2079 4.78955 10.6363 4.96699 10.9522 5.28284C11.268 5.5987 11.4455 6.02708 11.4455 6.47376Z"
									stroke="#F19B3E"
									strokeWidth="1.5"
								/>
								<path
									d="M18.1825 16.9999H1.34039M1.34039 7.73669L8.18334 2.26301C8.63128 1.90469 9.18782 1.70947 9.76145 1.70947C10.3351 1.70947 10.8916 1.90469 11.3396 2.26301L18.1825 7.73669"
									stroke="black"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M3.02399 17.0002V6.47388M16.4977 17.0002V6.47388"
									stroke="black"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
							</svg>
						</NavLink>
					</li>
					<li className="h-[55px] md:h-[51px]">
						<NavLink
							to="/search"
							className={({ isActive }) =>
								isActive
									? "border-[#F19B3E] sm:border-b-2 md:border-l-2 sm:pt-[2px] md:pr-[2px] bg-custom-gradient-sm md:bg-custom-gradient grid h-full w-full cursor-pointer"
									: "inactive-link grid h-full w-full cursor-pointer"
							}
						>
							<svg
								className="h-[20px] md:h-[20px] m-auto"
								viewBox="0 0 19 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16.8101 17.5303C17.103 17.8232 17.5778 17.8232 17.8707 17.5303C18.1636 17.2374 18.1636 16.7625 17.8707 16.4696L16.8101 17.5303ZM13.4569 12.0559L12.9266 11.5255L11.8659 12.5862L12.3963 13.1165L13.4569 12.0559ZM17.8707 16.4696L13.4569 12.0559L12.3963 13.1165L16.8101 17.5303L17.8707 16.4696Z"
									fill="#F19B3E"
								/>
								<circle
									cx="7.96108"
									cy="7.62069"
									r="6.62069"
									stroke="black"
									strokeWidth="1.5"
								/>
							</svg>
						</NavLink>
					</li>
					<li className="h-[55px] md:h-[51px]">
						<NavLink
							to="/notification"
							className={({ isActive }) =>
								isActive
									? "border-[#F19B3E] sm:border-b-2 md:border-l-2 sm:pt-[2px] md:pr-[2px] bg-custom-gradient-sm md:bg-custom-gradient grid h-full w-full cursor-pointer"
									: "inactive-link grid h-full w-full cursor-pointer"
							}
						>
							<svg
								className="h-[20px] md:h-[20px] m-auto"
								viewBox="0 0 17 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4.94039 14.6C5.46439 15.9984 6.87799 17 8.54039 17C10.2028 17 11.6164 15.9984 12.1404 14.6"
									stroke="#F19B3E"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M13.9404 7.168V6.604C13.9404 3.5088 11.5212 1 8.54044 1C5.55964 1 3.14044 3.5088 3.14044 6.604V7.168C3.14141 7.84124 2.94928 8.50064 2.58684 9.068L1.70044 10.448C0.891643 11.708 1.50924 13.4208 2.91644 13.8192C6.59333 14.8616 10.4876 14.8616 14.1644 13.8192C15.5716 13.4208 16.1892 11.708 15.3804 10.4488L14.494 9.0688C14.1313 8.50153 13.9389 7.84212 13.9396 7.1688L13.9404 7.168Z"
									stroke="black"
									strokeWidth="1.5"
								/>
							</svg>
						</NavLink>
					</li>
					<li className="h-[55px] md:h-[51px]">
						<NavLink
							to="/account"
							className={({ isActive }) =>
								isActive
									? "border-[#F19B3E] sm:border-b-2 md:border-l-2 sm:pt-[2px] md:pr-[2px] bg-custom-gradient-sm md:bg-custom-gradient grid h-full w-full cursor-pointer"
									: "inactive-link grid h-full w-full cursor-pointer"
							}
						>
							<svg
								className="h-[20px] md:h-[20px] m-auto"
								viewBox="0 0 19 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9.34039 8.99995C10.6659 8.99995 11.7404 7.92543 11.7404 6.59995C11.7404 5.27447 10.6659 4.19995 9.34039 4.19995C8.01491 4.19995 6.94039 5.27447 6.94039 6.59995C6.94039 7.92543 8.01491 8.99995 9.34039 8.99995Z"
									stroke="#F19B3E"
									strokeWidth="1.5"
								/>
								<path
									d="M14.1162 15.4C13.9882 13.0864 13.2802 11.4 9.34022 11.4C5.40022 11.4 4.69222 13.0864 4.56422 15.4"
									stroke="#F19B3E"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M9.34039 17C13.7587 17 17.3404 13.4183 17.3404 9C17.3404 4.58172 13.7587 1 9.34039 1C4.92212 1 1.34039 4.58172 1.34039 9C1.34039 13.4183 4.92212 17 9.34039 17Z"
									stroke="black"
									strokeWidth="1.5"
								/>
							</svg>
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	)
}
export default NavBar
