import React, { useState, useEffect } from "react"
import { useAuthUser } from "react-auth-kit"
import Entry from "./Entry"

function List({ contacts }) {
	const auth = useAuthUser()
	const user = auth()
	const [data, setData] = useState([])

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()

	const getDaysInMonth = (month, year) => {
		return new Date(year, month, 0).getDate()
	}
	const stats_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/engagements?userEngagementTypes=ADD_READING&startDate=${currentYear}-${currentMonth}-01&endDate=${currentYear}-${currentMonth}-${getDaysInMonth(
		currentMonth,
		currentYear
	)}&page=0&size=20`

	useEffect(() => {
		fetch(stats_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setData(result.content)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [stats_url, user.apikey])

	return (
		<ul>
			{data &&
				data.map((item) => {
					return <Entry item={item} key={item.id} />
				})}
			{data && (
				<div className="m-auto w-full text-center my-[5rem] text-[14px] text-[#f19b3e]">
					No Recent User Engagements
				</div>
			)}
		</ul>
	)
}

export default List
