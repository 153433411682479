import React from "react"
import ContactItem from "../ContactItem"

function List({ contacts }) {
	return (
		<ul>
			{!!contacts &&
				contacts.map((item) => {
					return (
						<ContactItem key={item.id} contact={item}>
							{item.msisdn}
						</ContactItem>
					)
				})}
		</ul>
	)
}

export default List
