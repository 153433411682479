import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { UserProvider } from "./context/UserContext"
import SharedLayout from "./pages/SharedLayoutPage/SharedLayoutPage"
import HomePage from "./pages/HomePage"
import SearchPage from "./pages/SearchPage"
import NewUserPage from "./pages/NewUserPage"

import NotificationsPage from "./pages/NotificationsPage"
import DailyReportPage from "./pages/DailyReportPage"
import AccountPage from "./pages/AccountPage/AccountPage"
import LoginPage from "./pages/LoginPage"
import ConfirmationPage from "./pages/ConfirmationPage"

// import RegistrationPage from "./pages/RegistrationPage"
import ResetPasswordPage from "./pages/ResetPasswordPage"
import { RequireAuth } from "react-auth-kit"
import "./App.css"

function App() {
	return (
		<UserProvider>
			<BrowserRouter>
				<Routes>
					<Route
						path="/"
						element={
							<RequireAuth loginPath="/login">
								<SharedLayout />
							</RequireAuth>
						}
					>
						<Route index element={<HomePage />} />
						<Route path="search" element={<SearchPage />}></Route>
						<Route
							path="notification"
							element={<NotificationsPage />}
						></Route>
						<Route
							path="report"
							element={<DailyReportPage />}
						></Route>
						<Route path="account" element={<AccountPage />}></Route>
						<Route
							path="register/:id/:tel"
							element={<NewUserPage />}
						></Route>
					</Route>
					<Route path="login" element={<LoginPage />}></Route>
					<Route
						path="reset-password"
						element={<ResetPasswordPage />}
					></Route>
					<Route
						path="confirmation/:phone"
						element={<ConfirmationPage />}
					></Route>
				</Routes>
			</BrowserRouter>
		</UserProvider>
	)
}

export default App
