import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { createPortal } from "react-dom"

function RegisterUser({ isOpen, onClick, onClose, cell, status }) {
	const navigate = useNavigate()

	const [isMounted, setIsMounted] = useState(false)
	useEffect(() => {
		setIsMounted(true)
		return () => setIsMounted(false)
	}, [])

	if (!isMounted || !isOpen) return null
	return createPortal(
		<div className="fixed z-[11] bg-white rounded-[2px] sm:w-[calc(100%-32px)] md:min-w-[453px] md:max-w-[553px] flex flex-col gap-[30px] sm:px-[16px] sm:py-[30px] md:p-[60px] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%]">
			<div className=" bg-cover  w-[100px] h-[100px] flex m-auto rounded-[10rem]">
				{status.success && (
					<svg
						className="h-[100px] pb-[3px] m-auto"
						viewBox="0 0 94 94"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="47" cy="47" r="35" fill="#FCFFE9" />
						<circle
							cx="47"
							cy="47"
							r="41"
							stroke="#F19B3D"
							strokeOpacity="0.1"
							strokeWidth="12"
						/>
						<circle cx="47" cy="47" r="35" fill="#FCFFE9" />
						<circle
							cx="47"
							cy="47"
							r="38"
							stroke="#F19B3D"
							strokeOpacity="0.15"
							strokeWidth="6"
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M12 47C12 37.7174 15.6875 28.815 22.2513 22.2513C28.815 15.6875 37.7174 12 47 12C56.2826 12 65.185 15.6875 71.7487 22.2513C78.3125 28.815 82 37.7174 82 47C82 56.2826 78.3125 65.185 71.7487 71.7487C65.185 78.3125 56.2826 82 47 82C37.7174 82 28.815 78.3125 22.2513 71.7487C15.6875 65.185 12 56.2826 12 47ZM45.0027 61.98L65.1533 36.7893L61.5133 33.8773L44.3307 55.3487L32.16 45.208L29.1733 48.792L45.0027 61.9847V61.98Z"
							fill="#F19B3D"
						/>
					</svg>
				)}
				{!status.success && (
					<svg
						className="h-[100px] pb-[3px] m-auto"
						viewBox="0 0 94 94"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="47" cy="47" r="35" fill="#FCFFE9" />
						<circle
							cx="47"
							cy="47"
							r="41"
							stroke="#F19B3D"
							strokeOpacity="0.1"
							strokeWidth="12"
						/>
						<circle cx="47" cy="47" r="35" fill="#FCFFE9" />
						<circle
							cx="47"
							cy="47"
							r="38"
							stroke="#F19B3D"
							strokeOpacity="0.15"
							strokeWidth="6"
						/>
						<path
							d="M47 12C27.5 12 12 27.5 12 47C12 66.5 27.5 82 47 82C66.5 82 82 66.5 82 47C82 27.5 66.5 12 47 12ZM60.5 64.5L47 51L33.5 64.5L29.5 60.5L43 47L29.5 33.5L33.5 29.5L47 43L60.5 29.5L64.5 33.5L51 47L64.5 60.5L60.5 64.5Z"
							fill="#F19B3D"
						/>
					</svg>
				)}
			</div>
			<div className="flex-col flex gap-[6px]">
				<button
					onClick={onClose}
					className="absolute top-[1.5rem] right-[1.5rem] hover:underline text-[#3FA456]"
				>
					Close
				</button>
				<div className="text-[20px] font-medium text-center">
					{status.title}
				</div>
				<div className="text-[14px] text-center leading-[1.3] font-normal">
					{status.message}
				</div>
			</div>
			<div className="grid justify-around">
				{status.success && (
					<button
						onClick={() => navigate(`/search`)}
						className="text-white bg-[#3FA456] text-[18px] font-normal leading-[48px] px-[25px] rounded-[8px]"
					>
						Done
					</button>
				)}
				{!status.success && (
					<button
						onClick={onClose}
						className="text-white bg-[#3FA456] text-[18px] font-normal leading-[48px] px-[25px] rounded-[8px]"
					>
						Go Back
					</button>
				)}
			</div>
		</div>,
		document.getElementById("modal-root")
	)
}

export default RegisterUser
