import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import Overlay from "../../components/Overlay"
import RegistationError from "../../components/RegistationError"
import { useAuthUser } from "react-auth-kit"
const NewUserPage = () => {
	const auth = useAuthUser()
	const user = auth()
	const { tel, id } = useParams()

	const [url] = useState(
		`${process.env.REACT_APP_BASE_URL}/api/v2/che-tool/collect/${id}`
	)

	const [showDiabetesMedsOptions, setShowDiabetesMedsOptions] =
		useState(false)
	const [showHypertensionMedsOptions, setShowHypertensionMedsOptions] =
		useState(false)
	const [isOpen, setIsOpen] = useState(false)

	const nameRef = useRef(null)
	const challenges = useRef(null)
	const verificationCodeRef = useRef(null)
	const clientType = useRef("First Time User")
	const dateOfBirth = useRef(null)
	const gender = useRef(null)
	const healthFacilityName = useRef("N/A")
	const healthStatus = useRef("N/A")
	const yearOfDiagnosis = useRef(null)
	const birthEstimated = useRef(true)
	const hasHealthConditionImproved = useRef(true)
	const isOnApp = useRef(true)

	const [diabetesMeds, setDiabetesMeds] = useState([])
	const [hypertensionMeds, setHypertensionMeds] = useState([])

	const [register, setRegister] = useState("Fetching Data")
	const [attendanceColor, setAttendanceColor] = useState("#d1d1d1")

	const [status, setStatus] = useState({})

	const diabetesMedications = [
		"Actrapid Insulin",
		"Hypurin Lente Insulin",
		"Hypurin Neutral Insulin",
		"Actrapid HM Insulin",
		"Humulin 70/30",
		"Humulin R",
		"Humulin N",
		"Humulin Regular",
		"Insuget 30/70",
		"Insuget",
		"Insuget Regular",
		"Insulidd 30/70",
		"Insulidd-L",
		"Insulidd-N",
		"Insulatard",
		"Mixtard 30",
		"Novomix 30",
		"NovoRapid",
		"Wosulin R",
		"Wosulin N",
		"Wosulin 30/70",
		"NovoRapid",
		"Lantus",
		"Lantus solostar",
		"Chlorpropamide",
		"Dapromide",
		"Diabenese",
		"Dibonis",
		"Wespromide",
		"Betanase",
		"Daonil",
		"Euglucon",
		"Glemide-5",
		"Glibenclamide",
		"Glibetics-5",
		"Glibil",
		"Gliboral",
		"Glicon",
		"Glimide",
		"Glitisol",
		"Melix",
		"Nogluc",
		"Amaryl",
		"Diapride",
		"Getryl",
		"Glim",
		"Glimulinl",
		"Glorin",
		"Glypride",
		"Glimauno-2",
		"Glyper",
		"Perglim",
		"Amaryl M",
		"Gemer",
		"Glimadien",
		"Diamicron MR",
		"Getzid MR",
		"Remicron MR",
		"Diagluc",
		"Glycigon",
		"Glycinorm",
		"Diactin",
		"Dibizine",
		"Glipistin",
		"Glucozid",
		"Glynase",
		"Glurenor",
		"Diavista",
		"Glitas",
		"Glitter",
		"Glizone",
		"Glustin",
		"Nilgar",
		"Piocare",
		"Pioday",
		"Pioglit",
		"Piogluc",
		"Pionorm",
		"Piosafe",
		"Pioz",
		"Piozer",
		"Piozone",
		"Zolid",
		"Pioday M",
		"Pioday Sm",
		"Pioglit MF",
		"Piosafe MF",
		"Zolid Plus",
		"Avandamet",
		"Avandamet",
		"Glucobay",
		"Ezentia",
		"Ezechol",
		"Ezirol",
		"Ezita",
		"Bigomet",
		"Bigomet SR",
		"Comet",
		"Diaphage",
		"Glucomet",
		"Glucoformin",
		"Glucophage",
		"Gludown",
		"Glyfor",
		"Glyformin",
		"Guamet",
		"Flomet",
		"Forminal 1000 SR",
		"Lipimet",
		"Melmet",
		"Metadien",
		"Metfil 500",
		"Metforal",
		"Metforcip",
		"Metformin",
		"Metformin-Denk",
		"Metphage",
		"Panfor",
		"Ranophage OD",
		"Duobet",
		"Duotrol",
		"Glucomet-N",
		"Glucored",
		"Glucored Forte",
		"Metcon",
		"Metcon-plus",
		"Glibomet",
		"Gliclaz-M",
		"NovoNorm",
		"Galvus",
		"Galvus MET",
	]

	const hypertensionMedications = [
		"Cadolol",
		"Canvas",
		"Carca",
		"Cardivas",
		"Dilatrend",
		"Vidol",
		"Xicard",
		"Anolol",
		"Arteryl",
		"Atecard",
		"Atenol",
		"Atenolol denk",
		"Atenolol",
		"Atpure",
		"Betanorm",
		"Betacard",
		"Blokium",
		"Cardinol",
		"Cardioten",
		"Lonet",
		"Merakle",
		"Preloc",
		"Tenormin",
		"Tredol",
		"Velorin",
		"Atecard-D",
		"Blokium Diu",
		"Tenoret",
		"Tenotic",
		"Tenoric-50",
		"Tenoric-100",
		"Beta Nicardia",
		"Nilol",
		"Carderal",
		"Emforal",
		"Hipertol",
		"Inderal",
		"Propranolol",
		"Betaloc ZOK",
		"Met XL",
		"Metoprolol",
		"Metpure-XL",
		"Revelol-XL",
		"Nebicard",
		"Nebilet",
		"Nebilong",
		"Nevol",
		"Nebilong-H",
		"Viskaldix",
		"Sotalol",
		"Apresoline",
		"Hydralazine",
		"Aldomet",
		"Biodopa",
		"Dawadopa",
		"Hydopa",
		"Medopress",
		"Methyldopa",
		"Hyperium",
		"Angiopril",
		"Capoten",
		"Capril",
		"Captocos",
		"Captolab",
		"Captopril",
		"Cardopril",
		"Epsitron",
		"Ropril",
		"Capozide",
		"Captopril",
		"Acepril",
		"Cardace",
		"Cortec",
		"Emilie",
		"E-pril",
		"Ena Denk",
		"Enam",
		"Enalapril",
		"Enaril-5",
		"Encardil",
		"Enril",
		"Envas",
		"Flamibopril",
		"Invoril",
		"Korandil",
		"Nuril",
		"Priloten",
		"Renitec",
		"Vasopril",
		"Enapril 20H",
		"Auroliza",
		"Lisace",
		"Lisinopril",
		"Listril",
		"Zestril",
		"Auriliza H",
		"Zestoretic",
		"Coversyl",
		"Prindo",
		"Bi-preterax",
		"Preterax",
		"Prindo-D",
		"Prindo-DF",
		"Cardiopril",
		"Corpril",
		"Tritace",
		"Variace",
		"Tritazide",
		"Mavik",
		"Advant",
		"ARB-8",
		"Atacand",
		"Candez",
		"Cantar",
		"Advantec",
		"Atacand plus",
		"Aprovel",
		"Irbetan",
		"Irovel",
		"Coaprovel",
		"Irbetan",
		"Irovel-H",
		"Alsartan",
		"Angilock",
		"Angitan",
		"Angizaar",
		"Bepsar",
		"Carditan",
		"Cozaar",
		"Lortan",
		"Losacar",
		"Losar",
		"Losartas",
		"Losatec",
		"Lazart",
		"Nusar",
		"Presartan",
		"Prosan",
		"Repace",
		"Resilo",
		"Tozaar",
		"Zyltan",
		"Alsartan-H",
		"Lora-HC",
		"Angizaar H",
		"Lortan-H",
		"Losacar-H",
		"Losastal-HL",
		"Losastal-H.T",
		"Lozart-H",
		"Losatec-H",
		"Nusar-H",
		"Repace-H",
		"Presartan-H",
		"ProsanHZ",
		"Tozaar-H",
		"Unizar-H",
		"Zyltan-H",
		"Micardis Plain",
		"Tasmi",
		"Telmikaa",
		"Teltas",
		"Telsart",
		"Unitel",
		"Co-Micardis Plus",
		"Cotasmi",
		"Telmi 40H",
		"Telmi 80H",
		"Unitel-H 40",
		"Unitel 80",
		"Diovan",
		"Co-diovan",
		"Glyceryl Trinitrate",
		"Nitromack",
		"GTN Spray",
		"Anginal",
		"Isosorbide Dinitrate",
		"Imdur",
		"Isosorbide Mononitrate",
		"Monis",
		"Monocontin OD",
		"Monotrate",
		"Adipin",
		"Amax",
		"Amdip",
		"Amdocal",
		"Amedin",
		"Amlo",
		"Amlo denk",
		"Amlocip",
		"Amlolit",
		"Amlong",
		"Amlopin",
		"Amlosun",
		"Amlovas",
		"Amlozest",
		"Asomex",
		"Caltas",
		"Esam",
		"Flamodip",
		"Lopicard",
		"Lofral",
		"Norvasc",
		"Primodil",
		"Provask",
		"Stamlo",
		"Varinil",
		"Primodil AT",
		"Caduet",
		"Amlocip NB",
		"Amloozaar",
		"Unizar",
		"Zyltan AM",
		"Telmi 40 Am",
		"Telmi 80 Am",
		"Exforge",
		"Exforge HCT",
		"s-Numlo",
		"Angizem",
		"Calzem SR",
		"Dilcontin XL",
		"Diltiazem SR",
		"Mono-Tildiem",
		"Tildiem",
		"Cabren",
		"Enfelo",
		"Felodil",
		"Plendil",
		"Logimax",
		"Lacipil",
		"Adalat",
		"Calcigard",
		"Cardia Retard",
		"Carditas",
		"Depin-N",
		"Nepif Retard",
		"Nicardia CD Retard",
		"Nicardin",
		"Nifed",
		"Nifedi-Denk",
		"Nifelong LA",
		"Nifedipine",
		"Nifelat R",
		"Nifelat Retard",
		"Zenusin SR",
		"Isoptin",
		"Verapamil",
		"Tarka",
		"Brinerdin",
		"Heparin",
		"Lioton",
		"Clexane",
		"LMW-enox",
		"Parin-E",
		"Chlorthiazide",
		"Hydrochlorthiazide",
		"Bendroflumethiazide",
		"Chlorthalidone",
		"Bendrofluazide",
		"Benduric",
		"Hydrochlorthiazide",
		"Indapamide",
		"Indicontin",
		"Diasix",
		"Frusalix",
		"Frusemide",
		"Frusun",
		"Furo Denk",
		"Fusid",
		"Lasix",
		"Puresis",
		"Inspra",
		"Aldactone",
		"spirolac",
		"Spirolone",
		"Spironolactone",
	]

	const [searchHTerm, setSearchHTerm] = useState("")
	const [searchDTerm, setSearchDTerm] = useState("")
	const [filteredDiabetesMedications, setFilteredDiabetesMedications] =
		useState(diabetesMedications)
	const [
		filteredHypertensionMedications,
		setFilteredHypertensionMedications,
	] = useState(hypertensionMedications)

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()
	const currentDay = new Date().getDate()

	const attendances_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(2, "0")}&endDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(
		2,
		"0"
	)}&page=0&size=20&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`

	const handleSubmit = () => {
		fetch(url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
			body: JSON.stringify({
				verificationCode: verificationCodeRef.current.value,
				challenges: challenges.current.value,
				clientType: clientType.current.value,
				dateOfBirth: dateOfBirth.current.value,
				birthEstimated: birthEstimated.current.value,
				diabeticMedicines: diabetesMeds,
				gender: gender.current.value,
				hasHealthConditionImproved:
					hasHealthConditionImproved.current.value,
				healthFacilityName: healthFacilityName.current.value,
				healthStatus: healthStatus.current.value,
				hypertensiveMedicines: hypertensionMeds,
				clientName: nameRef.current.value,
				clientPhone: tel,
				yearOfDiagnosis: yearOfDiagnosis.current.value,
				isOnApp: isOnApp.current.value,
			}),
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.id) {
					setStatus({
						success: true,
						title: "Registration Successful",
					})
				} else {
					setStatus({
						success: false,
						title: "Registration Unsuccessful",
						message: result.message,
					})
				}
				setIsOpen(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const addDiabetesMedication = (event) => {
		setSearchDTerm("")
		const index = diabetesMeds.findIndex((item) =>
			item.includes(event.target.innerText)
		)
		if (index >= 0) {
			return
		} else {
			setDiabetesMeds((prevMeds) => [...prevMeds, event.target.innerText])
		}
	}

	const addHypertensionMedication = (event) => {
		setSearchDTerm("")
		const index = hypertensionMeds.findIndex((item) =>
			item.includes(event.target.innerText)
		)
		if (index >= 0) {
			return
		} else {
			setHypertensionMeds((prevMeds) => [
				...prevMeds,
				event.target.innerText,
			])
		}
	}

	const removeDiabetesMedication = (medication) => {
		const index = diabetesMeds.findIndex((item) =>
			item.includes(medication)
		)
		const newItems = [
			...diabetesMeds.slice(0, index),
			...diabetesMeds.slice(index + 1),
		]
		setDiabetesMeds(newItems)
	}

	const removeHypertensionMedication = (medication) => {
		const index = hypertensionMeds.findIndex((item) =>
			item.includes(medication)
		)
		const newItems = [
			...hypertensionMeds.slice(0, index),
			...hypertensionMeds.slice(index + 1),
		]
		setHypertensionMeds(newItems)
	}

	const handleDiabetesMedsSearch = (event) => {
		const value = event.target.value
		setSearchDTerm(value)
		const filtered = diabetesMedications.filter((item) =>
			item.toLowerCase().includes(value.toLowerCase())
		)
		setFilteredDiabetesMedications(filtered)
	}
	const handleHypertensionMedsSearch = (event) => {
		const value = event.target.value
		setSearchHTerm(value)
		const filtered = hypertensionMedications.filter((item) =>
			item.toLowerCase().includes(value.toLowerCase())
		)
		setFilteredHypertensionMedications(filtered)
	}

	const blurDiabetesMeds = () => {
		setTimeout(() => {
			setShowDiabetesMedsOptions(false)
		}, 150)
	}

	const blurHypertensionMeds = () => {
		setTimeout(() => {
			setShowHypertensionMedsOptions(false)
		}, 150)
	}
	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.content[0].attendanceType === "P") {
					setRegister("Present")
					setAttendanceColor("#3fa456")
				} else if (result.content[0].attendanceType) {
					setRegister("Away")
					setAttendanceColor("#ff7373")
				} else {
					setRegister("Please mark attendance")
					setAttendanceColor("#d1d1d1")
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey])
	useEffect(() => {
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<section className="max-w-[1090px] w-full mx-auto px-[19px] sm:grid sm:pb-[80px]">
			<div className="flex justify-between py-[19px]">
				<div className="">
					<div className="text-[20px] font-bold">
						Register New User
					</div>
					<div
						className="text-[13px] mt-[3px]"
						style={{ color: attendanceColor }}
					>
						{register}
					</div>
				</div>
			</div>
			<div className="bg-white p-[19px] grid gap-[14px] rounded-[2px] sm:mt-[19px] mt-[10px] w-full sm:order-3">
				<div className="flex">
					<div className="text-[17px] font-medium">
						Register {tel}
					</div>
				</div>
				<ul className="grid sm:flex sm:flex-col md:grid-cols-[1fr_1fr] gap-[16px]">
					<li className="flex flex-col col-span-2">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Verification Code
						</div>
						<input
							required
							id="code"
							ref={verificationCodeRef}
							type="text"
							placeholder="Verification Code"
							className="  text-[#333333] rounded-[3px] h-[32px]  outline-none  px-[10px] text-[14px] shadow-inner-custom"
						/>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Full Name
						</div>
						<input
							required
							id="name"
							ref={nameRef}
							type="text"
							placeholder="Full Name"
							className="  text-[#333333] rounded-[3px] h-[32px]  outline-none  px-[10px] text-[14px] shadow-inner-custom"
						/>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Date of Birth
						</div>
						<input
							required
							id="dob"
							ref={dateOfBirth}
							type="date"
							placeholder="Date Of Birth"
							className="  text-[#333333] rounded-[3px] h-[32px]  outline-none  px-[10px] text-[14px] shadow-inner-custom w-full"
						/>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Gender
						</div>
						<select
							id="gender"
							name="gender"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px]  outline-none  px-[5px] text-[14px]"
							ref={gender}
						>
							<option value="Male" defaultValue>
								Male
							</option>
							<option value="Female">Female</option>
						</select>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Birth Estimated
						</div>
						<select
							id="birthEstimated"
							name="birthEstimated"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px]  outline-none  px-[5px] text-[14px]"
							ref={birthEstimated}
						>
							<option value="true" defaultValue>
								Yes
							</option>
							<option value="false">No</option>
						</select>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Has Health Condition Improved
						</div>
						<select
							id="hasHealthConditionImproved"
							name="hasHealthConditionImproved"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px]  outline-none  px-[5px] text-[14px]"
							ref={hasHealthConditionImproved}
						>
							<option value="true" defaultValue>
								Yes
							</option>
							<option value="false">No</option>
						</select>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Is The Client on Afya Pap
						</div>
						<select
							id="isOnApp"
							name="isOnApp"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px]  outline-none  px-[5px] text-[14px]"
							ref={isOnApp}
						>
							<option value="true" defaultValue>
								Yes
							</option>
							<option value="false">No</option>
						</select>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Health Status
						</div>
						<select
							id="hasHealthConditionImproved"
							name="hasHealthConditionImproved"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px]  outline-none  px-[5px] text-[14px]"
							ref={healthStatus}
						>
							<option value="N/A" defaultValue>
								N/A
							</option>
							<option value="Diabetes">Diabetes</option>
							<option value="Hypertension">Hypertension</option>
							<option value="Both">Both</option>
						</select>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Health facility name
						</div>
						<select
							id="healthFacilityName"
							name="healthFacilityName"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px]  outline-none  px-[5px] text-[14px]"
							ref={healthFacilityName}
						>
							<option value="N/A" defaultValue>
								N/A
							</option>
							<option value="Miluki Health Center">
								Miluki Health Center
							</option>
							<option value="Kabuchai Health Center">
								Kabuchai Health Center
							</option>
							<option value="Nguluni Health Center">
								Nguluni Health Center
							</option>
							<option value="Matungulu Health Center">
								Matungulu Health Center
							</option>
						</select>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Year of Diagnosis
						</div>
						<input
							required
							id="tel"
							ref={yearOfDiagnosis}
							type="text"
							placeholder="Year of diagnosis"
							className="  text-[#333333] rounded-[3px] h-[32px]  outline-none  px-[10px] text-[14px] shadow-inner-custom"
						/>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Client Type
						</div>
						<select
							id="healthFacilityName"
							name="healthFacilityName"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px]  outline-none  px-[5px] text-[14px]"
							ref={clientType}
						>
							<option value="First Time User" defaultValue>
								First Time User
							</option>
							<option value="Long Time User">
								Long Time User
							</option>
						</select>
					</li>

					<li className="flex flex-col relative">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Diabetes Medication
						</div>
						<input
							type="text"
							placeholder="Search..."
							value={searchDTerm}
							onChange={handleDiabetesMedsSearch}
							onFocus={() => setShowDiabetesMedsOptions(true)}
							onBlur={blurDiabetesMeds}
							className="  text-[#333333] rounded-[3px] h-[32px]  outline-none  px-[10px] text-[14px] shadow-inner-custom"
						/>

						{showDiabetesMedsOptions && (
							<ul className=" bg-[#ffeee0] absolute top-[3.25rem] w-full z-[5]">
								{filteredDiabetesMedications.map(
									(item, index) => (
										<li
											key={index}
											onClick={addDiabetesMedication}
											className="text-gray-700 pl-5 py-[0.5rem]  hover:bg-white cursor-pointer"
										>
											{item}
										</li>
									)
								)}
							</ul>
						)}
						{diabetesMeds.length > 0 && (
							<ul className="flex flex-wrap gap-[0.5rem] mt-[0.5rem]">
								{diabetesMeds.map((item, index) => (
									<li
										className="border-[#f2994a] bg-[#fff7f0] border-[1px] px-[10px] pt-[1px] pb-[2px] flex flex-row gap-[0.4rem] rounded-[4px]"
										key={index}
									>
										<span className="text-[11px] font-medium leading-6">
											{item}
										</span>
										<button
											className="font-light flex"
											onClick={() =>
												removeDiabetesMedication(item)
											}
										>
											<svg
												className="w-[20px] h-[20px] m-auto"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M5.33317 15.8334L4.1665 14.6667L8.83317 10L4.1665 5.33335L5.33317 4.16669L9.99984 8.83335L14.6665 4.16669L15.8332 5.33335L11.1665 10L15.8332 14.6667L14.6665 15.8334L9.99984 11.1667L5.33317 15.8334Z"
													fill="#f2994a"
												/>
											</svg>
										</button>
									</li>
								))}
							</ul>
						)}
					</li>

					<li className="flex flex-col relative">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Hypertension Medication
						</div>
						<input
							type="text"
							placeholder="Search..."
							value={searchHTerm}
							onChange={handleHypertensionMedsSearch}
							onFocus={() => setShowHypertensionMedsOptions(true)}
							onBlur={blurHypertensionMeds}
							className="  text-[#333333] rounded-[3px] h-[32px]  outline-none  px-[10px] text-[14px] shadow-inner-custom"
						/>

						{showHypertensionMedsOptions && (
							<ul className=" bg-[#ffeee0] absolute top-[3.25rem] w-full z-[5]">
								{filteredHypertensionMedications.map(
									(item, index) => (
										<li
											key={index}
											onClick={addHypertensionMedication}
											className="text-gray-700 pl-5 py-[0.5rem]  hover:bg-white cursor-pointer"
										>
											{item}
										</li>
									)
								)}
							</ul>
						)}

						{hypertensionMeds.length > 0 && (
							<ul className="flex flex-wrap gap-[0.5rem] mt-[0.5rem]">
								{hypertensionMeds.map((item, index) => (
									<li
										className="border-[#f2994a] bg-[#fff7f0] border-[1px] px-[10px] pt-[1px] pb-[2px] flex flex-row gap-[0.4rem] rounded-[4px]"
										key={index}
									>
										<span className="text-[11px] font-medium leading-6">
											{item}
										</span>
										<button
											className="font-light flex"
											onClick={() =>
												removeHypertensionMedication(
													item
												)
											}
										>
											<svg
												className="w-[20px] h-[20px] m-auto"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M5.33317 15.8334L4.1665 14.6667L8.83317 10L4.1665 5.33335L5.33317 4.16669L9.99984 8.83335L14.6665 4.16669L15.8332 5.33335L11.1665 10L15.8332 14.6667L14.6665 15.8334L9.99984 11.1667L5.33317 15.8334Z"
													fill="#f2994a"
												/>
											</svg>
										</button>
									</li>
								))}
							</ul>
						)}
					</li>
					<li className="flex flex-col col-span-2">
						<div className="text-[13px] font-medium] pl-[5px] pb-[5px]">
							Challenges Encountered
						</div>
						<textarea
							id="challenges"
							ref={challenges}
							type="text"
							placeholder="Challenges"
							className="  text-[#333333] rounded-[3px]   outline-none py-[7px] px-[10px] text-[14px] shadow-inner-custom"
							rows="5"
						></textarea>
					</li>
				</ul>

				<button
					onClick={handleSubmit}
					className="text-white bg-[#f2994a] rounded-[3px] flex h-[32px] text-[14px] mt-[2px]"
				>
					<span className="m-auto flex justify-center gap-[0.2rem]">
						<span className="text-[12px]">Submit</span>
					</span>
				</button>
			</div>
			<RegistationError
				status={status}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				cell={tel}
			/>
			<Overlay
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
			/>
		</section>
	)
}
export default NewUserPage
