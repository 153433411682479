import React, { useEffect, useState } from "react"
import { useAuthUser } from "react-auth-kit"
import Calender from "../../components/calender/Calender"
import BarGraph from "../../components/Graph/BarGraph"
import MarkAttendance from "../../components/calender/MarkAttendance"
import Stats from "../../components/dashboard/Stats"
import Overlay from "../../components/Overlay"
import Entries from "../../components/Stats/Entries"
const HomePage = () => {
	const auth = useAuthUser()
	const user = auth()

	const [isOpen, setIsOpen] = useState(false)
	const [isHelp, setIsHelp] = useState(false)
	const [register, setRegister] = useState("Fetching Data")
	const [attendanceColor, setAttendanceColor] = useState("#d1d1d1")

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()
	const currentDay = new Date().getDate()

	const attendances_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(2, "0")}&endDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(
		2,
		"0"
	)}&page=0&size=20&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`

	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setIsOpen(Boolean(!result.content.length))
				if (result.content[0].attendanceType === "P") {
					setRegister("Present")
					setAttendanceColor("#3fa456")
				} else if (result.content[0].attendanceType) {
					setRegister("Away")
					setAttendanceColor("#ff7373")
				} else {
					setRegister("Please mark attendance")
					setAttendanceColor("#d1d1d1")
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey, isOpen])

	useEffect(() => {
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<section className="max-w-[1090px] w-full mx-auto px-[19px] sm:grid sm:pb-[80px]">
			<div className="flex justify-between py-[19px]">
				<div className="">
					<div className="text-[20px] font-bold">
						Hello {user ? user.profile.name : "Guest"}
					</div>
					<div
						className="text-[13px] mt-[3px]"
						style={{ color: attendanceColor }}
					>
						{register}
					</div>
				</div>
			</div>
			<Stats />
			<div className="flex flex-wrap w-full gap-[19px] mt-[19px] sm:order-2">
				<BarGraph />
				<div className="bg-white p-[19px] flex flex-col gap-[14px] relative rounded-[2px] grow-[1]  md:min-w-[450px] sm:order-1">
					<div className="flex justify-between grow-0">
						<div className="text-[17px] font-medium">
							Attendance
						</div>
						<div className="flex gap-[1rem]">
							<button
								onClick={() => setIsHelp(true)}
								className="text-[#F19B3E] hover:text-[#ffffff]  active:text-[#F19B3E] text-[12px] leading-[27px]"
							>
								<div className="h-full flex w-[2rem] ">
									<svg
										className="w-[18px] h-[18px] m-auto"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1 9C1 5.2288 1 3.3432 2.1712 2.1712C3.344 1 5.2288 1 9 1C12.7712 1 14.6568 1 15.828 2.1712C17 3.344 17 5.2288 17 9C17 12.7712 17 14.6568 15.828 15.828C14.6576 17 12.7712 17 9 17C5.2288 17 3.3432 17 2.1712 15.828C1 14.6576 1 12.7712 1 9Z"
											stroke="#F19B3E"
											stroke-width="1.5"
										/>
										<path
											d="M7.5 6.5C7.50004 6.23731 7.56905 5.97924 7.70015 5.7516C7.83125 5.52397 8.01983 5.33475 8.24702 5.20289C8.47422 5.07102 8.73205 5.00113 8.99474 5.00021C9.25743 4.99929 9.51575 5.06737 9.74386 5.19764C9.97197 5.32791 10.1619 5.5158 10.2946 5.74251C10.4273 5.96922 10.4981 6.2268 10.5 6.48948C10.5018 6.75216 10.4347 7.01073 10.3053 7.23932C10.1758 7.4679 9.98863 7.65848 9.7624 7.792C9.3824 8.0168 9 8.3584 9 8.8V9.8"
											stroke="#F19B3E"
											stroke-width="1.5"
											stroke-linecap="round"
										/>
										<path
											d="M9 13C9.44183 13 9.8 12.6418 9.8 12.2C9.8 11.7582 9.44183 11.4 9 11.4C8.55817 11.4 8.2 11.7582 8.2 12.2C8.2 12.6418 8.55817 13 9 13Z"
											fill="black"
										/>
									</svg>
								</div>
							</button>
							<button
								onClick={() => setIsOpen(true)}
								className="text-[#F19B3E] bg-[#FEF7F0] hover:bg-[#F19B3E] hover:text-[#ffffff] active:bg-[#ffffff] active:text-[#F19B3E] text-[12px] leading-[27px] pb-[2px] rounded-[2px] px-[10px]"
							>
								Mark Attendance
							</button>
						</div>
						<MarkAttendance
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
						/>
						<Overlay
							isOpen={isOpen || isHelp}
							onClose={() => {
								setIsOpen(false)
								setIsHelp(false)
							}}
						/>
					</div>
					<Calender refresh={isOpen} />
					{isHelp && (
						<div className="bg-[#ffffff] z-20 absolute p-[0.5rem_1rem_1rem] min-w-[12rem] rounded-[4px] top-[3.5rem] right-[19px]">
							<ul className="flex flex-col gap-[0.5rem]">
								<div className="py-[0.25rem] flex justify-between">
									<div className="text-[0.9rem]">Help</div>
									<button
										className="w-[16px] h-[16px]"
										onClick={() => setIsHelp(false)}
									>
										<svg
											className="w-[16px] h-[16px]"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M18 9C18 13.9707 13.9707 18 9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9ZM6.273 6.273C6.39956 6.14659 6.57112 6.07559 6.75 6.07559C6.92888 6.07559 7.10044 6.14659 7.227 6.273L9 8.046L10.773 6.273C10.901 6.15377 11.0702 6.08886 11.2451 6.09194C11.4199 6.09503 11.5868 6.16587 11.7105 6.28954C11.8341 6.41321 11.905 6.58006 11.9081 6.75493C11.9111 6.9298 11.8462 7.09904 11.727 7.227L9.954 9L11.727 10.773C11.8462 10.901 11.9111 11.0702 11.9081 11.2451C11.905 11.4199 11.8341 11.5868 11.7105 11.7105C11.5868 11.8341 11.4199 11.905 11.2451 11.9081C11.0702 11.9111 10.901 11.8462 10.773 11.727L9 9.954L7.227 11.727C7.09904 11.8462 6.9298 11.9111 6.75493 11.9081C6.58006 11.905 6.41321 11.8341 6.28954 11.7105C6.16587 11.5868 6.09503 11.4199 6.09194 11.2451C6.08886 11.0702 6.15377 10.901 6.273 10.773L8.046 9L6.273 7.227C6.14659 7.10044 6.07559 6.92888 6.07559 6.75C6.07559 6.57112 6.14659 6.39956 6.273 6.273Z"
												fill="#F19B3E"
											/>
										</svg>
									</button>
								</div>
								<li className="inline-flex gap-[0.5rem]">
									<img
										alt="perfomance"
										src="/assets/bad.png"
										className="h-[14px] w-[14px]"
									/>
									<span className="text-[10px] font-[500] my-auto">
										Below 10 onboards
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<img
										alt="perfomance"
										src="/assets/good.png"
										className="h-[14px] w-[14px]"
									/>
									<span className="text-[10px] font-[500] my-auto">
										Reached 10 onboards
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<img
										alt="perfomance"
										src="/assets/perfect.png"
										className="h-[14px] w-[14px]"
									/>
									<span className="text-[10px] font-[500] my-auto">
										Over 10 onboards
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<div class="text-[10px] border-l-[1px] pl-[5px] leading-[1] text-[#F19B3E] font-bold">
										U
									</div>
									<span className="text-[10px] font-[500] my-auto">
										Date unavailble
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<div class="text-[10px] border-l-[1px] pl-[5px] leading-[1] text-[#F19B3E] font-bold">
										P
									</div>
									<span className="text-[10px] font-[500] my-auto">
										Present
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<div class="text-[10px] border-l-[1px] pl-[5px] leading-[1] text-[#F19B3E] font-bold">
										A
									</div>
									<span className="text-[10px] font-[500] my-auto">
										Absent
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<div class="text-[10px] border-l-[1px] pl-[5px] leading-[1] text-[#F19B3E] font-bold">
										H
									</div>
									<span className="text-[10px] font-[500] my-auto">
										Holiday
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<div class="text-[10px] border-l-[1px] pl-[5px] leading-[1] text-[#F19B3E] font-bold">
										PL
									</div>
									<span className="text-[10px] font-[500] my-auto">
										Planned Leave
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<div class="text-[10px] border-l-[1px] pl-[5px] leading-[1] text-[#F19B3E] font-bold">
										SL
									</div>
									<span className="text-[10px] font-[500] my-auto">
										Sick leave
									</span>
								</li>
								<li className="inline-flex gap-[0.5rem]">
									<div class="text-[10px] border-l-[1px] pl-[5px] leading-[1] text-[#F19B3E] font-bold">
										UL
									</div>
									<span className="text-[10px] font-[500] my-auto">
										Unplanned Leave
									</span>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
			<div className="bg-white p-[19px] grid gap-[14px] rounded-[2px] mt-[19px]  mb-[19px] sm:order-4">
				<div className="flex justify-between">
					<div className="text-[17px] font-medium">
						Recent Engagements
					</div>
				</div>
				<div className="border-t-[1px] border-[#fff5eb] h-full grow-[1]">
					<Entries />
				</div>
			</div>
		</section>
	)
}
export default HomePage
