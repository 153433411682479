import React, { useEffect, useState } from "react"
import { useAuthUser } from "react-auth-kit"
import DayReport from "../../components/DayReport"
import Overlay from "../../components/Overlay"

const NotificationsPage = () => {
	const auth = useAuthUser()
	const user = auth()

	const [register, setRegister] = useState("Fetching Data")
	const [attendanceColor, setAttendanceColor] = useState("#d1d1d1")
	const [loading, setLoading] = useState(false)
	const [date, setDate] = useState("")
	const [prospectiveEngagements, setProspectiveEngagements] = useState("")
	const [challengesFaced, setChallengesFaced] = useState([])

	const [isOpen, setIsOpen] = useState(false)

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()
	const currentDay = new Date().getDate()

	const options = [
		"App stopped bringing codes",
		"Slow code delivery",
		"Received multiple codes",
		"App stopped working",
		"Clients face automatic sign out from the app.",
		"The majority of the clients had no smart phones.",
		"Lack of facilitation",
	]

	const handleCheckboxChange = (option) => {
		if (challengesFaced.includes(option)) {
			// If option is already selected, remove it
			setChallengesFaced(
				challengesFaced.filter((item) => item !== option)
			)
		} else {
			// If option is not selected, add it
			setChallengesFaced([...challengesFaced, option])
		}
	}

	const attendances_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(2, "0")}&endDate=${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(
		2,
		"0"
	)}&page=0&size=20&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`

	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.content[0].attendanceType === "P") {
					setRegister("Present")
					setAttendanceColor("#3fa456")
				} else if (result.content[0].attendanceType) {
					setRegister("Away")
					setAttendanceColor("#ff7373")
				} else {
					setRegister("Please mark attendance")
					setAttendanceColor("#d1d1d1")
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey])

	const day_report_url = `${process.env.REACT_APP_BASE_URL}/pharmacy/reports/day-report`

	const currentDate = `${currentYear}-${currentMonth}-${String(
		currentDay
	).padStart(2, "0")}`

	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		fetch(day_report_url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${user.apikey}`,
			},
			body: JSON.stringify({
				prospectiveEngagements: Number(prospectiveEngagements),
				challengesFaced: challengesFaced,
				date: date,
			}),
		})
			.then((response) => response.json())
			.then((result) => {
				setLoading(false)
				console.log(result)
				setIsOpen(true)
			})
			.catch((error) => {
				setLoading(false)
				console.log(error)
			})
	}

	useEffect(() => {
		fetch(day_report_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${user.apikey}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				console.log(result)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [day_report_url, user.apikey])

	useEffect(() => {
		setDate(currentDate)
		return function () {
			window.scrollTo(0, 0)
		}
	}, [currentDate])

	return (
		<section className="max-w-[535px] w-full mx-auto px-[19px] sm:grid sm:pb-[80px]">
			<div className="flex justify-between py-[19px]">
				<div className="">
					<div className="text-[20px] font-bold">Daily Report</div>
					<div
						className="text-[13px] mt-[3px]"
						style={{ color: attendanceColor }}
					>
						{register}
					</div>
				</div>
			</div>
			<form
				className="py-[19px] grid gap-[14px] rounded-[2px] sm:order-4"
				onSubmit={handleSubmit}
			>
				<div className=" md:w-[30rem]">
					<div className="flex gap-[10px] flex-col mb-[20px]">
						<label
							htmlFor="referral"
							className="font-[600] text-[14px] ml-[4px] text-[#333333]"
						>
							Date
						</label>
						<div className="relative">
							<input
								required
								id="prospective"
								type="date"
								value={date}
								placeholder="Number of Prospective Engagements"
								onChange={(e) => setDate(e.target.value)}
								className="h-[42px] w-full outline-none px-[10px] rounded-[5px] corner shadow-inner-custom"
							/>
						</div>
					</div>
					<div className="flex gap-[10px] flex-col mb-[20px]">
						<label
							htmlFor="referral"
							className="font-[600] text-[14px] ml-[4px] text-[#333333]"
						>
							Prospective Engagements
						</label>
						<div className="relative">
							<input
								required
								id="prospective"
								type="number"
								value={prospectiveEngagements}
								placeholder="0"
								onChange={(e) =>
									setProspectiveEngagements(e.target.value)
								}
								className="h-[42px] w-full outline-none px-[10px] rounded-[5px] corner shadow-inner-custom"
							/>
						</div>
					</div>
					<div className="mt-[2rem] md:w-[30rem]">
						<div className="flex gap-[10px] flex-col mb-[20px]">
							<label
								htmlFor="referral"
								className="font-[600] text-[14px] ml-[4px] text-[#333333]"
							>
								Challenges Faced
							</label>
						</div>
						<div className="flex flex-col gap-[1rem]">
							{options.map((option, index) => (
								<div className="flex gap-2" key={index}>
									<input
										type="checkbox"
										id={index}
										value={option}
										checked={challengesFaced.includes(
											option
										)}
										onChange={() =>
											handleCheckboxChange(option)
										}
										className="relative cursor-pointer peer shrink-0 appearance-none w-4 h-4 border-2 border-[#F19B3E] rounded-sm bg-white checked:bg-[#F19B3E] checked:border-0"
									/>
									<label
										htmlFor={index}
										className="font-[500] text-[14px] ml-[4px] text-[#333333]  cursor-pointer"
									>
										{option}
									</label>
									<svg
										className="absolute w-4 h-4 hidden pointer-events-none peer-checked:block"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="#ffffff"
										strokeWidth="4"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<polyline points="20 6 9 17 4 12"></polyline>
									</svg>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="mt-[2rem]">
					<button
						disabled={loading}
						className="text-[16px] w-[184px] mx-auto bg-[#f2994a] text-white leading-[44px] h-[44px] rounded-[5px] font-[600] flex items-center justify-center"
					>
						{!loading && "Submit"}
						{loading && (
							<div className="animate-spin rounded-full h-[16px] w-[16px] border-t-2 border-white"></div>
						)}
					</button>
				</div>
			</form>

			<DayReport
				onClose={() => {
					setIsOpen(false)
				}}
				isOpen={isOpen}
				date={date}
			/>
			<Overlay
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
			/>
		</section>
	)
}
export default NotificationsPage
